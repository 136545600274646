<template>
    <multiselect
      :multiple="multiple"
      :allowEmpty="allowEmpty"
      @search-change="handleSearch"
      :label="label ? label : 'name'"
      :track-by="trackBy ? trackBy : 'name'"
      :disabled="disabled"
      :options="(optionsData && optionsData.length > 0) ? optionsData : (options != undefined ? options : [])"
      v-model="selected"
      :placeholder="placeholder ? placeholder : 'type to search'"
      :select-label="customSelectLabel"
      :deselect-label="customDeselectLabel"
    />
</template>
  
<script>
  export default {
    props: {
      multiple: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },      
      warehouseId: {
        type: Number,
        required: false,
      },
      placeholder: {
        type: String,
      },
      label: {
        type: String,
      },
      baseUrl: {
        type: String,
      },
      allowEmpty: {
        type: Boolean,
        default: true,
      },
      trackBy: {
        type: String,
      },
      value: {},
      options: {
        required: false,
      },      
    },
    data() {
      return {
        typedValue: "",
        timeOuts: null,
        optionsData: [],
        selected: this.value ? this.value : {},
        customSelectLabel: "",
        customDeselectLabel: "",
      };
    },
    methods: {
        handleSearch(v) {
            this.typedValue = v;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.get, 400);
        },
        get() {
            // this.$vs.loading();
            this.$http
            .get(this.baseUrl, {
                params: {
                    order: "id",
                    sort: "asc",
                    warehouse_id: this.warehouseId,
                    query: this.typedValue
                },
            })
            .then((resp) => {
              console.log(resp)
                if (resp.code == 200) {
                    this.optionsData = resp.data;
                    // if (!this.value && resp.data.default) {
                    //     this.selected = resp.data.default;
                    //     this.$emit("selected", resp.data.default);
                    // }
                } else {
                    this.optionsData = [];
                }
            });
        },
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            this.selected = v;
        },
    },
    mounted() {
      this.get();
      // if (this.baseUrl != "" && this.baseUrl != undefined) {
      // } else if (this.options.length > 0) {
      //   this.optionsData = this.options;
      // }
    },
  };
</script>
  
<style>
    .multiselect--above .multiselect__content-wrapper {
        bottom: auto !important;
        top: 100% !important;
    }
</style>